// import axios from "axios";
// import React, { useEffect, useState, useRef } from "react";
// import SpeechRecognition, {
//   useSpeechRecognition,
// } from "react-speech-recognition";
// import { Button } from "react-bootstrap";
// import ChatLoader from "../Loader/ChatLoader";

// const Distaphone = () => {
//   const {
//     transcript,
//     listening,
//     resetTranscript,
//     startListening,
//     stopListening,
//     browserSupportsSpeechRecognition,
//   } = useSpeechRecognition();
//   const ref = useRef(false);
//   const [response, setResponse] = useState([]);
//   const [apiKey, setApiKey] = useState(
//     "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"
//   );
//   const [isLoading, setIsLoading] = useState(false);
//   const [questions, setQuestions] = useState([]);
//   const [responseHistory, setResponseHistory] = useState([]);
//   const [debounceTimeout, setDebounceTimeout] = useState(null);
//   const [isSpeaking, setIsSpeaking] = useState(false);

//   const [latestQuestion, setLatestQuestion] = useState(""); // State for the latest question
//   const [latestResponse, setLatestResponse] = useState(""); // State for the latest response

//   const [isButtonDisabled, setIsButtonDisabled] = useState(true);

//   const [hideBtns, setHideBtns] = useState(false);

//   useEffect(() => {
//     // Load data from localStorage when the component mounts
//     const savedQuestions = localStorage.getItem("questions");
//     if (savedQuestions) {
//       setQuestions(JSON.parse(savedQuestions));
//     }

//     const savedResponseHistory = localStorage.getItem("responseHistory");
//     if (savedResponseHistory) {
//       setResponseHistory(JSON.parse(savedResponseHistory));
//     }
//   }, []);

//   useEffect(() => {
//     // Save data to localStorage when it changes
//     localStorage.setItem("questions", JSON.stringify(questions));
//     localStorage.setItem("responseHistory", JSON.stringify(responseHistory));
//   }, [questions, responseHistory]);

//   // Function to add a new question and response
//   const addQuestionAndResponse = (question, response) => {
//     setQuestions((prevQuestions) => [...prevQuestions, question]);
//     setResponseHistory((prevHistory) => [...prevHistory, response]);

//     // Update the latest question and response
//     setLatestQuestion(question);
//     setLatestResponse(response);
//   };

//   // USE EFFECT FOR VOICE INPUT
//   useEffect(() => {
//     if (transcript && listening) {
//       if (debounceTimeout) {
//         clearTimeout(debounceTimeout);
//       }

//       // Set a new debounce timer to call the API after 5 seconds of inactivity
//       const newDebounceTimeout = setTimeout(() => {
//         sendToChatGPT(transcript);
//       }, 4000);

//       setDebounceTimeout(newDebounceTimeout);
//     }
//     ref.current = true;
//   }, [transcript, listening]);

//   if (!browserSupportsSpeechRecognition) {
//     return <span>Browser doesn't support speech recognition.</span>;
//   }

//   const sendToChatGPT = async (transcript) => {
//     if (apiKey) {
//       setIsLoading(true);

//       try {
//         const url = "https://api.openai.com/v1/chat/completions";
//         const headers = {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${apiKey}`,
//         };
//         const data = {
//           model: "gpt-3.5-turbo",
//           max_tokens: 1000,
//           messages: [
//             {
//               role: "user",
//               content: transcript,
//             },
//           ],
//         };
//         const response = await axios.post(url, data, { headers });

//         if (response.status === 200) {
//           const responseText = response.data.choices[0].message.content;
//           setResponse(responseText);
//           addQuestionAndResponse(transcript, responseText);
//         } else {
//           console.error(
//             "ChatGPT API call failed:",
//             response.status,
//             response.statusText
//           );
//         }
//       } catch (error) {
//         console.error("Error calling ChatGPT API:", error);
//       } finally {
//         setIsLoading(false);
//         setIsSpeaking(false);
//         resetTranscript();
//         setResponse(""); // Clear the response
//       }
//     }
//   };

//   // console.log("responseHistory:", responseHistory);

//   const resetAllData = () => {
//     localStorage.removeItem("questions");
//     localStorage.removeItem("responseHistory");
//     setQuestions([]);
//     setResponseHistory([]);
//   };

//   // console.log("isLoading", isLoading);
//   console.log("questions:", questions);
//   // console.log("latestQuestion==>", latestQuestion);
//   console.log("latestResponse==>", latestResponse);

//   // const clickHandlerOfSpeak = () => {
//   //   setIsSpeaking(true);
//   //   SpeechRecognition.startListening();
//   // };

//   //SET ENABLE TO SPEAK BUTTON
//   const enableSpeakButton = () => {
//     setIsButtonDisabled(false);

//     setIsSpeaking(true);
//     SpeechRecognition.startListening();

//     // Disable the Speak button after 15 seconds
//     setTimeout(() => {
//       setIsButtonDisabled(true);
//     }, 10000);
//     setHideBtns(false);
//   };

//   return (
//     <>
//       <div className="dicta-container">
//         <p className="microphone margin-cls">
//           Microphone: {listening ? "on" : "off"}
//         </p>
//         <div>
//           {isButtonDisabled ? (
//             <button
//               className="disable-btn margin-cls"
//               onClick={enableSpeakButton}
//             >
//               Click Here to Speak
//             </button>
//           ) : (
//             ""
//           )}
//         </div>
//         <div className="d-flex justify-content-between strt-rst-btn-wrapper">
//           <div></div>
//           <Button
//             variant="success"
//             // onClick={SpeechRecognition.startListening}
//             // onClick={clickHandlerOfSpeak}
//             onClick={enableSpeakButton}
//             style={{ marginRight: "10px" }}
//             disabled={isButtonDisabled}
//             className="ms-auto speak-disabled"
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="30"
//               height="30"
//               fill="currentColor"
//               className="bi bi-megaphone"
//               viewBox="0 0 16 16"
//             >
//               <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z" />
//             </svg>
//           </Button>
//           {/* <Button
//           variant="danger"
//           onClick={SpeechRecognition.stopListening}
//           style={{ marginRight: "10px" }}
//         >
//           Stop
//         </Button> */}

//           {isButtonDisabled ? (
//             <Button
//               variant="info"
//               onClick={resetAllData}
//               className="reset-btn ms-auto"
//             >
//               {/* Reset */}
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="30"
//                 height="30"
//                 fill="currentColor"
//                 viewBox="0 0 16 16"
//               >
//                 <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
//                 <path d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
//               </svg>
//             </Button>
//           ) : (
//             <div className="reset-btn ms-auto"></div>
//           )}
//         </div>
//         {/* <div>
//           <button className="disable-btn" onClick={enableSpeakButton}>
//             Click here to enable speak button
//           </button>
//         </div> */}
//         <p className="transcript-btn margin-cls">{transcript}</p>
//       </div>

//       <div className="chat-container">
//         {isSpeaking || isLoading ? (
//           <ChatLoader />
//         ) : (
//           <>
//             {questions.map((question, index) => (
//               <div className="conversation" key={index}>
//                 <div className="user-question">{question}</div>
//                 <div className="bot-answer">{responseHistory[index]}</div>
//               </div>
//             ))}
//           </>
//         )}
//       </div>
//     </>
//   );
// };

// export default Distaphone;

import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Button } from "react-bootstrap";
import ChatLoader from "../Loader/ChatLoader";

const Distaphone = () => {
  const {
    transcript,
    listening,
    resetTranscript,
    startListening,
    stopListening,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const ref = useRef(false);
  const [response, setResponse] = useState([]);
  const [apiKey, setApiKey] = useState(
    "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"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [responseHistory, setResponseHistory] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);

  const [latestQuestion, setLatestQuestion] = useState("");
  const [latestResponse, setLatestResponse] = useState("");

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [hideBtns, setHideBtns] = useState(false);

  useEffect(() => {
    const savedQuestions = localStorage.getItem("questions");
    if (savedQuestions) {
      setQuestions(JSON.parse(savedQuestions));
    }

    const savedResponseHistory = localStorage.getItem("responseHistory");
    if (savedResponseHistory) {
      setResponseHistory(JSON.parse(savedResponseHistory));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("questions", JSON.stringify(questions));
    localStorage.setItem("responseHistory", JSON.stringify(responseHistory));
  }, [questions, responseHistory]);

  const addQuestionAndResponse = (question, response) => {
    setQuestions((prevQuestions) => [...prevQuestions, question]);
    setResponseHistory((prevHistory) => [...prevHistory, response]);

    setLatestQuestion(question);
    setLatestResponse(response);
  };

  useEffect(() => {
    if (transcript && listening) {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const newDebounceTimeout = setTimeout(() => {
        sendToChatGPT(transcript);
      }, 4000);

      setDebounceTimeout(newDebounceTimeout);
    }
    ref.current = true;
  }, [transcript, listening]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const enableSpeakButton = () => {
    setIsButtonDisabled(false);

    setIsSpeaking(true);
    SpeechRecognition.startListening();

    setTimeout(() => {
      setIsButtonDisabled(true);
      setHideBtns(true);
    }, 15000);
  };

  const handleApiResponse = (responseText) => {
    setIsButtonDisabled(true);
    setHideBtns(true);

    setResponse(responseText);
    addQuestionAndResponse(transcript, responseText);
  };

  const sendToChatGPT = async (transcript) => {
    if (apiKey) {
      setIsLoading(true);

      try {
        const url = "https://api.openai.com/v1/chat/completions";
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        };
        const data = {
          model: "gpt-3.5-turbo",
          max_tokens: 1000,
          messages: [
            {
              role: "user",
              content: transcript,
            },
          ],
        };
        const response = await axios.post(url, data, { headers });

        if (response.status === 200) {
          const responseText = response.data.choices[0].message.content;
          handleApiResponse(responseText);
        } else {
          console.error(
            "ChatGPT API call failed:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error calling ChatGPT API:", error);
      } finally {
        setIsLoading(false);
        setIsSpeaking(false);
        resetTranscript();
        setResponse("");
      }
    }
  };

  const resetAllData = () => {
    localStorage.removeItem("questions");
    localStorage.removeItem("responseHistory");
    setQuestions([]);
    setResponseHistory([]);
  };

  return (
    <>
      <div className="dicta-container">
        <p className="microphone margin-cls">
          Microphone: {listening ? "on" : "off"}
        </p>
        <div>
          {isButtonDisabled ? (
            <button
              className="disable-btn margin-cls"
              onClick={enableSpeakButton}
            >
              Click Here to Speak
            </button>
          ) : (
            <button className="disable-btn margin-cls"></button>
          )}
        </div>
        <div className="d-flex justify-content-between strt-rst-btn-wrapper">
          <div></div>
          <Button
            variant="success"
            onClick={enableSpeakButton}
            style={{ marginRight: "10px" }}
            disabled={isButtonDisabled}
            className={`ms-auto speak-disabled ${hideBtns ? "hidden" : ""}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-megaphone"
              viewBox="0 0 16 16"
            >
              <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z" />
            </svg>
          </Button>
          {isButtonDisabled ? (
            <Button
              variant="info"
              onClick={resetAllData}
              className="reset-btn ms-auto"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                <path d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
              </svg>
            </Button>
          ) : (
            <div className="reset-btn ms-auto"></div>
          )}
        </div>
        <p className="transcript-btn margin-cls">{transcript}</p>
      </div>

      <div className="chat-container">
        {isSpeaking || isLoading ? (
          <ChatLoader />
        ) : (
          <>
            {questions.map((question, index) => (
              <div className="conversation" key={index}>
                <div className="user-question">{question}</div>
                <div className="bot-answer">{responseHistory[index]}</div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default Distaphone;
