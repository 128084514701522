import React from "react";
import "./App.css";
import Distaphone from "./components/Distaphone/Distaphone";
import ChatLoader from "./components/Loader/ChatLoader";

const App = () => {
  return (
    <div>
      <Distaphone />
    </div>
  );
};

export default App;
